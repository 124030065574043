import React from "react";
import PropTypes from "prop-types";
import { Box, IconButton, Flex, Text, Center } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { CloseIcon, SettingsIcon } from "@chakra-ui/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTachometerAlt,
  faClipboardList,
  faProjectDiagram,
  faCalendar
} from "@fortawesome/free-solid-svg-icons";
import "../styling/sidebar.css";

import logo from "../assets/logo.png";

function Sidebar({ projectsList, onClose, activeMenuItem }) {
  const totalProjects = 4;

  return (
    <Box
      className="sidebar"
      w={{ base: "full", md: "250px" }}
      position="fixed"
      minH="100vh"
      bg="gray.50"
      zIndex={20}
      pt="3"
      pb="3"
    >
      <IconButton
        className="close-btn"
        position="absolute"
        top="3"
        right="3"
        icon={<CloseIcon />}
        onClick={onClose}
        size="sm"
        zIndex={21}
        display={{ base: "flex", md: "none" }}
      />
      <Flex className="logo-section" direction="column" align="center" mb="3">
        <img src={logo} alt="Logo" className="logo" />
      </Flex>

      <Box className="menu-section">
        <Text className="section-title" mt="5" mb="2">
          Options
        </Text>
        <Link
          className={`menu-item ${
            activeMenuItem === "Dashboard" ? "active" : ""
          }`}
          to="/dashboard"
        >
          <FontAwesomeIcon icon={faTachometerAlt} />
          <Text ml="3">Dashboard</Text>
        </Link>
        <Link
          className={`menu-item ${
            activeMenuItem === "Deadlines" ? "active" : ""
          }`}
          to="/deadlines"
        >
          <FontAwesomeIcon icon={faCalendar} />
          <Text ml="3">Deadlines</Text>
        </Link>
        <Link
          className={`menu-item ${
            activeMenuItem === "Manage Projects" ? "active" : ""
          }`}
          to="/manage"
        >
          <FontAwesomeIcon icon={faProjectDiagram} />
          <Text ml="3">Manage Projects</Text>
        </Link>
        <Text className="section-title" mt="5" mb="2">
          SUBJECTS ({projectsList.length}/{totalProjects})
        </Text>
        {projectsList.map((project, index) => (
          <Link key={index} className="menu-item" to={`/project/${project.title}`}>
            <FontAwesomeIcon icon={faClipboardList} />
            <Text ml="3">{project.title}</Text>
          </Link>
        ))}
      </Box>

      <Center className="bottom-section">
      </Center>
    </Box>
  );
}

Sidebar.propTypes = {
  projectsList: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  activeMenuItem: PropTypes.string.isRequired,
};

export default Sidebar;
