import React, { useEffect, useState } from "react";
import { useAuth } from "../../services/AuthContext";
import { logout } from "../../services/AuthenticationService";
import Sidebar from "../../components/sidebar";
import Navbar from "../../components/navbar";
import Calendar from "../../components/calendar";
import "../../styling/calendar.css";
import {
  Box,
  Flex,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  useDisclosure,
  IconButton,
  VStack,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import apiServiceInstance from "../../services/ApiService";

function Deadlines() {
  const { currentUser, projects } = useAuth();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [deadlines, setDeadlines] = useState([]);

  useEffect(() => {
    const fetchDeadlines = async () => {
      try {
        if (currentUser && currentUser.email) {
          const data = await apiServiceInstance.get(
            `deadline?userId=${currentUser.email}`
          );
          setDeadlines(data);
        }
      } catch (error) {
        console.error("Error fetching deadlines:", error);
      }
    };

    fetchDeadlines();
  }, [currentUser]);

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  return (
    <Flex direction={{ base: "column", md: "row" }}>
      <IconButton
        aria-label="Open Menu"
        size="lg"
        icon={<HamburgerIcon />}
        display={{ base: "inline-flex", md: "none" }}
        onClick={onOpen}
      />
      <Drawer placement="left" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <Sidebar
            projectsList={projects}
            onClose={onClose}
            activeMenuItem="Deadlines"
          />
        </DrawerContent>
      </Drawer>

      <Box
        display={{ base: "none", md: "block" }}
        w={{ md: "252px" }}
        minH="100vh"
      >
        <Sidebar
          projectsList={projects}
          onClose={onClose}
          activeMenuItem="Deadlines"
        />
      </Box>

      <VStack flex="1" w="full" spacing={0} align="stretch">
        <Navbar
          userName={currentUser ? currentUser.email : ""}
          title="Deadlines"
          onLogout={handleLogout}
        />
        <Box
          p={{ base: "2", md: "4" }} // Adjust padding based on the viewport size
          bg="white"
          boxShadow="md"
          rounded="lg"
          border="1px"
          borderColor="gray.200"
          w="full" // Ensure the box takes the full width
          minW="0" // Override any minimum width constraints
          marginX="auto" // Center the box if it's not taking the full width
        >
          <Calendar deadlines={deadlines} />
        </Box>
      </VStack>
    </Flex>
  );
}

export default Deadlines;
