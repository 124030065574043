import React from "react";
import { Box } from "@chakra-ui/react";

function StatCard({ title, value }) {
  return (
    <Box
      textAlign="center"
      p="20px"
      bg="white"
      borderRadius="lg"
      boxShadow="md"
      w="100%"
      h="150px"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <h3 style={{ color: "#333", fontSize: "14px", marginBottom: "10px", fontWeight: "bold" }}>
        {title}
      </h3>
      <p style={{ color: "#333", fontSize: "40px", fontWeight: "bold" }}>{value}</p>
    </Box>
  );
}

export default StatCard;
