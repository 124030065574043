import { Box, Flex } from "@chakra-ui/react";
import StatCard from "./statCard";

// Component for the stat cards
function StatCardBox({ title, value }) {
  return (
    <Box flex="1" mx="1">
      <StatCard title={title} value={value} />
    </Box>
  );
}

// Component for rounded cards with content
function RoundedCardBox({ children }) {
  return (
    <Box p="4" w="45%" borderRadius="md" boxShadow="md">
      {children}
    </Box>
  );
}

export { StatCardBox, RoundedCardBox };
