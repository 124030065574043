import React, { useState, useEffect } from 'react';
import { Box, Text } from '@chakra-ui/react';

function TaskList({ deadlines }) {
  const [colorMap, setColorMap] = useState({});

  useEffect(() => {
    const newColorMap = {};
    const colors = ["blue.200", "green.200", "orange.200", "red.200", "purple.200"];

    deadlines.forEach((project, index) => {
      newColorMap[project.projectId] = colors[index % colors.length];
    });

    setColorMap(newColorMap);
  }, [deadlines]);

  const generateDeadlineList = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Normalize today's date by removing time

    const upcomingDeadlines = deadlines.flatMap(project =>
      project.deadlines
        .filter(deadline => new Date(deadline.date) >= today) // Filter for future deadlines
        .map(deadline => ({
          ...deadline,
          projectTitle: project.title,
          projectId: project.projectId
        }))
    );
    
    const formatDate = (dateString) => {
      const utcDate = new Date(dateString);
      const localDate = new Date(utcDate.toLocaleString('en-US', { timeZone: 'UTC' }));
      return localDate.toLocaleDateString(); // Format date as a string in user's local time zone
    };

    const sortedDeadlines = upcomingDeadlines
      .sort((a, b) => new Date(a.date) - new Date(b.date))
      .slice(0, 4); // Get only the first 4 deadlines

    return sortedDeadlines.map(deadline => {
      const bgColor = colorMap[deadline.projectId] || "gray.200";
      return (
        <Box key={deadline.deadlineId} p={2} m={1} bg={bgColor} borderRadius="md">
          <Text fontSize="sm" fontWeight="bold" mb={1}>
            {deadline.projectTitle} - {deadline.title}
          </Text>
          <Text fontSize="xs" color="gray.600">
            Project ID: {deadline.projectId} 
          </Text>
          <Text fontSize="xs" color="gray.600">
            Due Date: {new Date(formatDate(deadline.date)).toLocaleDateString()}
          </Text>
        </Box>
      );
    });
  };

  return <Box>{generateDeadlineList()}</Box>;
}

export default TaskList;
