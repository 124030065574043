import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  VStack,
  Input,
  Text,
  Flex,
  IconButton,
  Heading,
  Tooltip,
  Container,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useToast,
} from "@chakra-ui/react";
import { EditIcon, AddIcon, DeleteIcon, HamburgerIcon } from "@chakra-ui/icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import apiServiceInstance from "../services/ApiService";
import { AuthContext } from "../services/AuthContext";

const Notes = ({ projectId }) => {
  const [notes, setNotes] = useState([]);
  const [currentNote, setCurrentNote] = useState({ title: "", content: "" });
  const [isEditing, setIsEditing] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { currentUser } = useContext(AuthContext);
  const toast = useToast();

  useEffect(() => {
    if (currentUser) {
      const fetchNotes = async () => {
        try {
          const response = await apiServiceInstance.get(
            `notes?projectId=${projectId}`
          );
          setNotes(response);
        } catch (error) {
          toast({
            title: "Error fetching notes",
            status: "error",
            duration: 2000,
          });
        }
      };
      fetchNotes();
    }
  }, [currentUser, projectId]);

  const handleEditorChange = (content) => {
    setCurrentNote({ ...currentNote, content });
  };

  const handleTitleChange = (e) => {
    setCurrentNote({ ...currentNote, title: e.target.value });
  };

  const saveNote = async () => {
    if (!currentNote.title || currentNote.title.trim().length === 0) {
      toast({
        title: "Error",
        description: "Note title cannot be empty.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (currentNote.title.includes(" ")) {
      toast({
        title: "Error",
        description: "Note title cannot contain spaces.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const existingNoteIndex = notes.findIndex(
        (note) => note.title === currentNote.title
      );
      if (existingNoteIndex >= 0) {
        const existingNote = notes[existingNoteIndex];
        const updatedNote = {
          ...existingNote,
          content: currentNote.content,
          title: currentNote.title, // Update the title if it has changed
        };
        const updatedNotes = [...notes];
        updatedNotes[existingNoteIndex] = updatedNote;
        setNotes(updatedNotes);
        await apiServiceInstance.put(
          `/notes/${currentUser.email}/${existingNote.title}`,
          {
            title: currentNote.title, // Send the new title in the request
            content: currentNote.content,
          }
        );
      } else {
        const newNote = await apiServiceInstance.post("/notes", {
          title: currentNote.title,
          content: currentNote.content,
          userId: currentUser.email,
          projectId: currentNote.projectId,
        });
        setNotes([...notes, newNote]);
      }
    } catch (error) {
      console.error("Error saving/updating note:", error);
      toast({
        title: "Error",
        description: "Failed to save/update note.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }

    setIsEditing(false);
    setIsMenuOpen(false);
  };

  const addNewNote = () => {
    setCurrentNote({ title: "", content: "", projectId: projectId });
    setIsEditing(true);
    setIsMenuOpen(false);
  };

  const selectNote = (note) => {
    setCurrentNote(note);
    setIsEditing(false);
    setIsMenuOpen(false);
  };

  const deleteNote = async (title, e) => {
    e.stopPropagation();
    try {
      await apiServiceInstance.delete("/notes", {
        title: title,
        userId: currentUser.email,
      });
      setNotes(notes.filter((note) => note.title !== title));
      if (currentNote.title === title) {
        setCurrentNote({ title: "", content: "" });
      }
      toast({
        title: "Success",
        description: "Note deleted successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error deleting note:", error);
      toast({
        title: "Error",
        description: "Failed to delete note.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  const toggleEditMode = () => {
    setIsEditing(!isEditing);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const NotesList = () => (
    <VStack spacing={2} align="stretch" w="full">
      {notes &&
        Array.isArray(notes) &&
        notes.map((note, index) => (
          <Flex
            key={index}
            onClick={() => selectNote(note)}
            justifyContent="space-between"
            alignItems="center"
            p={2}
            bg="gray.100"
            borderRadius="md"
            _hover={{ bg: "teal.100" }}
          >
            <Text flex={1}>{note.title}</Text>
            <IconButton
              icon={<DeleteIcon />}
              onClick={(e) => deleteNote(note.title, e)}
              size="sm"
              aria-label="Delete Note"
              variant="ghost"
            />
          </Flex>
        ))}
    </VStack>
  );

  return (
    <Container maxW="container.xl" p={0}>
      <Flex h="100vh" py={5}>
        <IconButton
          aria-label="Open Menu"
          icon={<HamburgerIcon />}
          onClick={toggleMenu}
          display={{ md: "none" }}
          m={2}
        />

        <Drawer isOpen={isMenuOpen} placement="left" onClose={toggleMenu}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Notes</DrawerHeader>
            <DrawerBody>
              <Button
                leftIcon={<AddIcon />}
                colorScheme="teal"
                variant="outline"
                w="full"
                onClick={addNewNote}
              >
                Add Note
              </Button>
              <NotesList />
            </DrawerBody>
          </DrawerContent>
        </Drawer>

        <VStack
          w="250px"
          h="full"
          p={5}
          spacing={4}
          alignItems="flex-start"
          borderRight="1px solid #E2E8F0"
          display={{ base: "none", md: "flex" }}
        >
          <Button
            leftIcon={<AddIcon />}
            colorScheme="teal"
            variant="outline"
            w="full"
            onClick={addNewNote}
          >
            Add Note
          </Button>
          <NotesList />
        </VStack>

        <Box flex="1" p={5}>
          {isEditing ? (
            <>
              <Input
                placeholder="Note Title"
                value={currentNote.title}
                onChange={handleTitleChange}
                mb={3}
                disabled={isEditing && !!currentNote._id} // Disable title editing for existing notes when editing
              />
              <ReactQuill
                value={currentNote.content}
                onChange={handleEditorChange}
                theme="snow"
              />
              <Button mt={4} colorScheme="teal" onClick={saveNote}>
                Save Note
              </Button>
            </>
          ) : (
            <>
              <Flex justifyContent="space-between" alignItems="center" mb={3}>
                <Heading size="lg">
                  {currentNote.title || "Select a note"}
                </Heading>
                <Tooltip label="Edit Note">
                  <IconButton
                    icon={<EditIcon />}
                    onClick={toggleEditMode}
                    isRound
                    aria-label="Edit Note"
                  />
                </Tooltip>
              </Flex>
              <Box p={5} borderWidth="1px" borderRadius="lg" bg="white">
                <Text
                  whiteSpace="pre-wrap"
                  dangerouslySetInnerHTML={{
                    __html:
                      currentNote.content ||
                      "<p>Select a note or add a new one to get started.</p>",
                  }}
                />
              </Box>
            </>
          )}
        </Box>
      </Flex>
    </Container>
  );
};

export default Notes;
