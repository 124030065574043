import React, { useState, useEffect } from "react";
import { useAuth } from "../../services/AuthContext";
import { logout } from "../../services/AuthenticationService";
import { useNavigate } from "react-router-dom";
import apiServiceInstance from "../../services/ApiService";
import loadingGif from "../../assets/loading.gif";
import "../../styling/dashboard.css";
import Sidebar from "../../components/sidebar";
import Navbar from "../../components/navbar";
import ProjectTable from "../../components/projectTable";
import TaskList from "../../components/tasks";
import SimpleCalendar from "../../components/simpleCalendar";
import StatCard from "../../components/statCard";
import { RoundedCardBox, StatCardBox } from "../../components/roundedCard";

import {
  Flex,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  useDisclosure,
  VStack,
  IconButton,
  CloseButton,
  Box,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";

function Dashboard() {
  const { currentUser, projects, setProjects } = useAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [deadlines, setDeadlines] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    let isMounted = true;

    const fetchProjectsAndDeadlines = async () => {
      setIsLoading(true);
      try {
        if (currentUser && currentUser.email) {
          // Fetch projects
          const projectsEndpoint = `/projects?userId=${currentUser.email}`;
          const projectsData = await apiServiceInstance.get(projectsEndpoint);

          // Fetch deadlines
          const deadlinesEndpoint = `deadline?userId=${currentUser.email}`;
          const deadlinesData = await apiServiceInstance.get(deadlinesEndpoint);

          if (isMounted) {
            setProjects(projectsData);
            setDeadlines(deadlinesData);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        if (isMounted) {
          setProjects([]);
          setDeadlines([]);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchProjectsAndDeadlines();

    return () => {
      isMounted = false;
    };
  }, [currentUser, navigate, setProjects]);

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  if (isLoading) {
    return (
      <div className="loading-container">
        <img src={loadingGif} alt="Loading..." />
      </div>
    );
  }

  const projectsList = Array.isArray(projects) ? projects : [];

  return (
    <Flex direction={{ base: "column", md: "row" }}>
      {/* Mobile Sidebar Drawer */}
      <IconButton
        aria-label="Open Menu"
        size="lg"
        icon={<HamburgerIcon />}
        display={{ base: "inline-flex", md: "none" }}
        onClick={onOpen}
        zIndex="overlay"
      />
      <Drawer placement="left" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <CloseButton onClick={onClose} />
          <Sidebar
            projectsList={projectsList}
            onClose={onClose}
            activeMenuItem="Dashboard"
          />
        </DrawerContent>
      </Drawer>

      <Box
        display={{ base: "none", md: "block" }}
        w={{ md: "252px" }}
        minH="100vh"
      >
        <Sidebar
          projectsList={projectsList}
          onClose={onClose}
          activeMenuItem="Dashboard"
        />
      </Box>

      <VStack flex="1" w="full" spacing={0} align="stretch">
        <Navbar
          userName={currentUser.email}
          title="Dashboard"
          onLogout={handleLogout}
        />
        <Flex direction="column" w="full">
          <Flex p="4" w="100%" justify="space-around" mb="20px">
            <StatCardBox
              title="Number of Projects"
              value={projectsList.length}
              flex="1"
            />
            <StatCardBox
              title="Number of Tasks Left"
              value={deadlines.length}
              flex="1"
            />
          </Flex>

          {/* Box containing the content */}
          <Flex direction="row" w="100%" justify="space-around" mb="20px">
            <RoundedCardBox flex="1" mr="20px">
              <TaskList deadlines={deadlines} />
            </RoundedCardBox>
            <RoundedCardBox flex="1" ml="20px">
              <ProjectTable />
            </RoundedCardBox>
          </Flex>
        </Flex>
      </VStack>
    </Flex>
  );
}

export default Dashboard;
