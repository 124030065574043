import React, { createContext, useContext, useState, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './AuthenticationService';

export const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [projects, setProjects] = useState([]); // State to store projects

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      setCurrentUser(user);
    });

    return unsubscribe;
  }, []);

  // AuthContext now also provides projects data and a method to update it
  return (
    <AuthContext.Provider value={{ currentUser, projects, setProjects }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
