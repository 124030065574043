import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  VStack,
  Text,
  Input,
  IconButton,
} from "@chakra-ui/react";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import { AuthContext } from "../services/AuthContext";
import apiServiceInstance from "../services/ApiService";

function TodoList({ projectId }) {
  const { currentUser } = useContext(AuthContext);
  const [tasks, setTasks] = useState([]);
  const [taskInput, setTaskInput] = useState("");

  useEffect(() => {
    fetchTasks();
  }, [currentUser, projectId]);

  const fetchTasks = async () => {
    if (!currentUser || !currentUser.email || !projectId) {
      console.log("User not logged in or project ID not found");
      return;
    }

    try {
      const todosEndpoint = `/todos?userId=${currentUser.email}&projectId=${projectId}`;
      const tasksData = await apiServiceInstance.get(todosEndpoint);
      setTasks(tasksData);
    } catch (error) {
      console.error("Error fetching tasks:", error);
    }
  };

  // Function to add a new task
  const addTask = async () => {
    if (taskInput.trim() !== "") {
      const newTask = {
        title: taskInput.trim(),
        projectId: projectId,
        userId: currentUser.email,
        status: "incomplete",
      };

      try {
        const response = await apiServiceInstance.post('/todos', newTask);
        const addedTask = response.data;
        setTasks([...tasks, addedTask]);
        setTaskInput("");

        // Call fetchTasks again to re-fetch the tasks
        fetchTasks();
      } catch (error) {
        console.error("Error adding task:", error);
      }
    }
  };

  const deleteTask = async (taskId) => {
    try {
        await apiServiceInstance.delete(`/todos?userId=${currentUser.email}&title=${taskId}`);
        console.log("Task deleted:", taskId);
        
        // Filter out the deleted task from the tasks state
        setTasks(tasks.filter(task => task.title !== taskId));
    } catch (error) {
        console.error("Error in DELETE request:", error.message);
        console.error("Error deleting task:", error);
    }
};

  return (
    <Box p="4">
      <VStack spacing={4} align="stretch">
        <Input
          value={taskInput}
          onChange={(e) => setTaskInput(e.target.value)}
          placeholder="Enter a new task"
        />
        <IconButton
          icon={<AddIcon />}
          onClick={addTask}
          colorScheme="green"
          aria-label="Add task"
        />
      </VStack>
      <Box mt="4">
        {tasks.map((task, index) => (
          <Box
            key={index}
            p="2"
            bg="white"
            boxShadow="md"
            border="1px solid"
            borderColor="gray.300"
            borderRadius="lg"
            display="flex"
            alignItems="center"
            marginBottom="4"
          >
            <Text
              flex="1"
              mr="2"
              textDecoration={task && task.status === "done" ? "line-through" : "none"}
            >
              {task && task.title}
            </Text>
            <IconButton
              icon={<DeleteIcon />}
              onClick={() => deleteTask(task.title)}
              colorScheme="green"
              aria-label="Delete task"
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default TodoList;
