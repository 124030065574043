import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  IconButton,
  Flex,
  Grid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import "../styling/calendar.css";

function Calendar({ deadlines }) {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [colorMap, setColorMap] = useState({});

  useEffect(() => {
    const newColorMap = {};
    const colors = [
      "blue.200",
      "green.200",
      "orange.200",
      "red.200",
      "purple.200",
    ];

    deadlines.forEach((project, index) => {
      newColorMap[project.projectId] = colors[index % colors.length];
    });

    setColorMap(newColorMap);
  }, [deadlines]);

  const daysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const prevMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1)
    );
  };

  const nextMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1)
    );
  };

  const generateCalendar = () => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const numberOfDays = daysInMonth(year, month);
    const days = [];
    const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const today = new Date();

    for (let day = 1; day <= numberOfDays; day++) {
      const date = new Date(year, month, day);
      const dateStr = `${year}-${String(month + 1).padStart(2, "0")}-${String(
        day
      ).padStart(2, "0")}`;
      const deadlineItems = [];

      deadlines.forEach((project) => {
        project.deadlines.forEach((deadline) => {
          if (new Date(deadline.date).toISOString().startsWith(dateStr)) {
            const bgColor = colorMap[project.projectId] || "gray.200";
            deadlineItems.push(
              <Box
                bg={bgColor}
                p={2}
                m={1}
                borderRadius="md"
                key={deadline.deadlineId}
              >
                {project.title} - {deadline.title}
              </Box>
            );
          }
        });
      });

      const dayOfWeek = weekdays[date.getDay()];
      const isToday = date.toDateString() === today.toDateString();
      const borderColor = isToday ? "red.500" : "gray.200";
      const borderWidth = isToday ? "2px" : "1px";

      days.push(
        <Box key={day} p={4} border={borderWidth} borderColor={borderColor}>
          <Text fontSize="lg">{day}</Text>
          <Text fontSize="sm">{dayOfWeek}</Text>
          <Flex direction="column">{deadlineItems}</Flex>
        </Box>
      );
    }

    return days;
  };

  const formatDate = (dateString) => {
    const utcDate = new Date(dateString);
    const localDate = new Date(utcDate.toLocaleString('en-US', { timeZone: 'UTC' }));
    return localDate.toLocaleDateString(); 
  };

  const generateDeadlineList = () => {
    const allDeadlines = deadlines.flatMap(project =>
      project.deadlines.map(deadline => ({
        ...deadline,
        projectTitle: project.title,
        projectId: project.projectId
      }))
    );

    const sortedDeadlines = allDeadlines.sort((a, b) => new Date(a.date) - new Date(b.date));

    return sortedDeadlines.map(deadline => {
      const bgColor = colorMap[deadline.projectId] || "gray.200";
      return (
        <Box key={deadline.deadlineId} p={2} m={1} bg={bgColor} borderRadius="md">
          <Text fontSize="md" fontWeight="bold" mb={1}>
            {deadline.projectTitle} - {deadline.title}
          </Text>
          <Text fontSize="sm" color="gray.600">
            Project ID: {deadline.projectId}
          </Text>
          <Text fontSize="sm" color="gray.600">
            Due Date: {formatDate(deadline.date)}
          </Text>
        </Box>
      );
    });
  };

  return (
    <Tabs isFitted variant="enclosed">
      <TabList>
        <Tab>Calendar View</Tab>
        <Tab>Deadline List</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <Flex align="center" mb={4} justify="space-between" px={2}>
            <IconButton
              icon={<ChevronLeftIcon />}
              size="sm"
              onClick={prevMonth}
              aria-label="Previous Month"
            />
            <Text fontSize={{ base: "md", md: "xl" }}>
              {currentDate.toLocaleString("default", { month: "long" })}{" "}
              {currentDate.getFullYear()}
            </Text>
            <IconButton
              icon={<ChevronRightIcon />}
              size="sm"
              onClick={nextMonth}
              aria-label="Next Month"
            />
          </Flex>
          <Box className="calendar-container">
            <Grid
              templateColumns={{ base: "repeat(3, 1fr)", md: "repeat(7, 1fr)" }}
              gap={2}
            >
              {generateCalendar()}
            </Grid>
          </Box>
        </TabPanel>
        <TabPanel>
          <Box>{generateDeadlineList()}</Box>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}

export default Calendar;
