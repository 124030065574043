export const firebaseConfig = {
    apiKey: "AIzaSyBFiw7rmHRcyxlbfCjUwP_E53k9Hs9jAIs",
    authDomain: "studdy-dacf9.firebaseapp.com",
    projectId: "studdy-dacf9",
    storageBucket: "studdy-dacf9.appspot.com",
    messagingSenderId: "384046061831",
    appId: "1:384046061831:web:cfebcbe74468c51850cda2"
}

export const ApiConfig = {
    domain: "http://localhost:3001/api/",
    prod: "https://api.studdy.lol/api"
};
