import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Navbar from '../../components/navbar';
import {
  VStack, Flex, Box, Button, Tabs, TabList, TabPanels, Tab, TabPanel, Icon, useTheme
} from '@chakra-ui/react';
import { logout } from '../../services/AuthenticationService';
import { useAuth } from '../../services/AuthContext';
import { FaArrowLeft, FaStickyNote, FaClock, FaList, FaRegAddressCard } from 'react-icons/fa';
import Notes from '../../components/notes';
import TaskManager from '../../components/taskManager';
import CueCards from '../../components/cuecards';
import TodoList from '../../components/todolist';

function Project() {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  let { projectTitle } = useParams();

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const theme = useTheme();

  const tabStyle = {
    fontWeight: 'bold',
    color: '#333',
    textTransform: 'uppercase',
    _selected: {
      color: theme.colors.blue[500],
      borderBottomColor: theme.colors.blue[500],
    },
  };

  return (
    <Flex direction={{ base: 'column', md: 'row' }} pt={5}>
      <VStack flex="1" w="full" spacing={4} align="stretch">
        <Navbar
          userName={currentUser.email}
          title={projectTitle}
          onLogout={handleLogout}
          showDashboardButton={1}
        />
        <Tabs variant="enclosed" colorScheme="blue">
          <TabList>
            <Tab sx={tabStyle}>
              <Icon as={FaStickyNote} mr={2} />
              Notes
            </Tab>
            <Tab sx={tabStyle}>
              <Icon as={FaClock} mr={2} />
              Deadlines
            </Tab>
            <Tab sx={tabStyle}>
              <Icon as={FaList} mr={2} />
              To-Do List
            </Tab>
            <Tab sx={tabStyle}>
              <Icon as={FaRegAddressCard} mr={2} />
              Cue Cards
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <Notes projectId={projectTitle}></Notes>
            </TabPanel>
            <TabPanel>
              <TaskManager projectId={projectTitle}></TaskManager>
            </TabPanel>
            <TabPanel>
              <TodoList projectId={projectTitle}></TodoList>
            </TabPanel>
            <TabPanel>
              <CueCards projectId={projectTitle}></CueCards>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </VStack>
    </Flex>
  );
}

export default Project;
