import axios from "axios";
import { ApiConfig } from "../config";

class ApiService {
  constructor() {
    this.client = axios.create({
      baseURL: ApiConfig.prod, // change to prod on if using prod
    });
  }

  async get(endpoint) {
    try {
      const response = await this.client.get(endpoint);
      return response.data;
    } catch (error) {
      // Handle error appropriately
      console.error("Error in GET request:", error);
      throw error;
    }
  }

  async post(endpoint, payload = {}) {
    try {
      const response = await this.client.post(endpoint, payload);
      return response.data;
    } catch (error) {
      // Handle error appropriately
      console.error("Error in POST request:", error);
      throw error;
    }
  }

  async delete(endpoint, payload = {}) {
    try {
      const response = await this.client.delete(endpoint, { data: payload });
      return response.data;
    } catch (error) {
      // Handle error appropriately
      console.error("Error in DELETE request:", error);
      throw error;
    }
  }

  async put(endpoint, payload = {}) {
    try {
      const response = await this.client.put(endpoint, payload);
      return response.data;
    } catch (error) {
      console.error("Error in PUT request:", error);
      throw error;
    }
  }
}

const apiServiceInstance = new ApiService();
export default apiServiceInstance;
