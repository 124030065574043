import React, { useState, useEffect } from 'react';
import { useAuth } from '../../services/AuthContext';
import { useNavigate } from 'react-router-dom';
import { login, signup } from '../../services/AuthenticationService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt, faUserPlus, faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons';
import {
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Input,
  Button,
  FormControl,
  FormLabel,
  InputGroup,
  InputLeftElement,
  FormErrorMessage,
  VStack,
  useMediaQuery,
} from '@chakra-ui/react';
import '../../styling/login.css';

import logo from '../../assets/logo.png';

function Login() {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [activeIndex, setActiveIndex] = useState(0);
  const [isLargerThan48em] = useMediaQuery('(min-width: 48em)');

  useEffect(() => {
    if (currentUser) {
      navigate('/dashboard');
    }
  }, [currentUser, navigate]);

  const handleLogin = async () => {
    setError('');
    try {
      await login(email, password);
      navigate('/dashboard');
    } catch (error) {
      setError(error.message); // Set error message returned by Firebase
    }
  };

  const handleSignup = async () => {
    setError('');
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    try {
      await signup(email, password);
      navigate('/dashboard');
    } catch (error) {
      setError(error.message); // Set error message returned by Firebase
    }
  };

  const handleTabsChange = (index) => {
    setActiveIndex(index);
  };

  return (
    <div className="login-container">
      <Box className="login-card">
        <Box className="form-section">
          <br></br>
          <VStack spacing={4} align="stretch" alignItems="center">
            <img src={logo} alt="Logo" width="150" height="150" />
            <br></br>
            <Tabs isFitted variant="enclosed" index={activeIndex} onChange={handleTabsChange}>
              <TabList>
                <Tab className='tab-text'>Login</Tab>
                <Tab className='tab-text'>Sign Up</Tab>
              </TabList>
              <TabPanels>
                <TabPanel className={activeIndex === 0 ? 'fade-enter-active' : 'fade-exit-active'}>
                  <FormControl isInvalid={!!error}>
                    {error && <FormErrorMessage>{error}</FormErrorMessage>}
                    <FormLabel className='form-input form-text' htmlFor="email">Email</FormLabel>
                    <InputGroup>
                      <InputLeftElement
                        pointerEvents="none"
                        children={<FontAwesomeIcon icon={faEnvelope} className="icon-style" />}
                      />
                      <Input
                        id="email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className='form-input-sect'
                      />
                    </InputGroup>
                    <FormLabel className='form-input form-text' htmlFor="password">Password</FormLabel>
                    <InputGroup>
                      <InputLeftElement
                        pointerEvents="none"
                        children={<FontAwesomeIcon icon={faLock} className="icon-style" />}
                      />
                      <Input
                        id="password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className='form-input-sect'
                      />
                    </InputGroup>
                    <Button mt={4} _hover={{ bgGradient: 'linear(to-r, teal.400, green.500)' }} bgGradient="linear(to-r, teal.400, green.500)" className="login-button" colorScheme="blue" onClick={handleLogin}>
                      <FontAwesomeIcon icon={faSignInAlt} className={`icon-style ${'button-icon-style'}`} /> Login
                    </Button>
                  </FormControl>
                </TabPanel>
                <TabPanel className={activeIndex === 1 ? 'fade-enter-active' : 'fade-exit-active'}>
                  <FormControl isInvalid={!!error}>
                    {error && <FormErrorMessage>{error}</FormErrorMessage>}
                    <FormLabel className='form-input form-text' htmlFor="signup-email">Email</FormLabel>
                    <InputGroup>
                      <InputLeftElement
                        pointerEvents="none"
                        children={<FontAwesomeIcon icon={faEnvelope} className="icon-style" />}
                      />
                      <Input
                        id="signup-email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className='form-input-sect'
                      />
                    </InputGroup>
                    <FormLabel className='form-input form-text' htmlFor="signup-password">Password</FormLabel>
                    <InputGroup>
                      <InputLeftElement
                        pointerEvents="none"
                        children={<FontAwesomeIcon icon={faLock} className="icon-style" />}
                      />
                      <Input
                        id="signup-password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className='form-input-sect'
                      />
                    </InputGroup>
                    <FormLabel className='form-input form-text' htmlFor="confirm-password">Confirm Password</FormLabel>
                    <InputGroup>
                      <InputLeftElement
                        pointerEvents="none"
                        children={<FontAwesomeIcon icon={faLock} className="icon-style" />}
                      />
                      <Input
                        id="confirm-password"
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        className='form-input-sect'
                      />
                    </InputGroup>
                    <Button mt={4} _hover={{ bgGradient: 'linear(to-r, teal.400, green.500)' }} bgGradient="linear(to-r, teal.400, green.500)" className="signup-button" colorScheme="blue" onClick={handleSignup}>
                      <FontAwesomeIcon icon={faUserPlus} className={`icon-style ${'button-icon-style'}`} /> Sign Up
                    </Button>
                  </FormControl>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </VStack>
        </Box>
        {isLargerThan48em && (
          <Box className="image-section">
          </Box>
        )}
      </Box>
    </div>
  );  
}

export default Login;
