import React, { useState } from "react";
import { useAuth } from "../../services/AuthContext";
import { logout } from "../../services/AuthenticationService";
import Sidebar from "../../components/sidebar";
import Navbar from "../../components/navbar";
import apiServiceInstance from "../../services/ApiService";
import {
  Box,
  Flex,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  useDisclosure,
  IconButton,
  VStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon, EditIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";

function ManageProjects() {
  const { currentUser, projects, setProjects } = useAuth();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isCreateModalOpen, setCreateModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [editingProject, setEditingProject] = useState(null);
  const [newProjectTitle, setNewProjectTitle] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [projectToDelete, setProjectToDelete] = useState(null);

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const handleDelete = async (title) => {
    try {
      const payload = { userId: currentUser.email };
      await apiServiceInstance.delete(`/projects/${title}`, payload);
      setProjects(
        projects.filter((project) => project.title !== title)
      );
      setSuccessMessage("Project deleted successfully.");
    } catch (error) {
      console.error("Error deleting project:", error);
      setErrorMessage("Failed to delete project.");
    }
  };

  const confirmDelete = async () => {
    if (projectToDelete) {
      await handleDelete(projectToDelete.title);
      setDeleteModalOpen(false);
    }
  };

  const openDeleteModal = (project) => {
    setProjectToDelete(project);
    setDeleteModalOpen(true);
  };

  const handleCreateButtonClick = () => {
    if (projects.length >= 4) {
      setSuccessMessage("");
      setErrorMessage("You cannot create more than 4 projects.");
    } else {
      setSuccessMessage("");
      setCreateModalOpen(true);
    }
  };

  const handleCreateNewProject = async () => {
    try {
      const payload = {
        title: newProjectTitle,
        userId: currentUser.email,
      };

      const newProject = await apiServiceInstance.post("/projects", payload);

      setProjects([...projects, newProject]);

      setCreateModalOpen(false);
      setNewProjectTitle("");
      setSuccessMessage("Project created successfully.");
    } catch (error) {
      console.error("Error creating new project:", error);
      setErrorMessage("Failed to create project.");
    }
  };

  const handleEditInputChange = (e) => {
    setEditingProject({ ...editingProject, title: e.target.value });
  };

  const handleEditProject = async () => {
    if (!editingProject || !editingProject.title) {
      console.error("No project selected or title provided for editing");
      return;
    }

    try {
      const payload = {
        newTitle: editingProject.title,
        userId: currentUser.email,
      };

      const updatedProject = await apiServiceInstance.put(
        `/projects/${editingProject.projectId}`,
        payload
      );

      setProjects(
        projects.map((project) =>
          project.projectId === updatedProject.projectId
            ? updatedProject
            : project
        )
      );
      setEditModalOpen(false);
      setSuccessMessage("Project edited successfully.");
    } catch (error) {
      console.error("Error editing project:", error);
      setErrorMessage("Failed to edit project.");
    }
  };

  return (
    <Flex direction={{ base: "column", md: "row" }}>
      <IconButton
        aria-label="Open Menu"
        size="lg"
        icon={<HamburgerIcon />}
        display={{ base: "inline-flex", md: "none" }}
        onClick={onOpen}
      />
      <Drawer placement="left" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <Sidebar
            projectsList={projects}
            onClose={onClose}
            activeMenuItem="Manage Projects"
          />
        </DrawerContent>
      </Drawer>

      <Box
        display={{ base: "none", md: "block" }}
        w={{ md: "252px" }}
        minH="100vh"
      >
        <Sidebar
          projectsList={projects}
          onClose={onClose}
          activeMenuItem="Manage Projects"
        />
      </Box>

      <VStack flex="1" w="full" spacing={0} align="stretch">
        <Navbar
          userName={currentUser.email}
          title="Manage Projects"
          onLogout={handleLogout}
        />
        <Box p="4">
          {successMessage && (
            <Alert status="success" mb="4">
              <AlertIcon />
              {successMessage}
            </Alert>
          )}
          {errorMessage && (
            <Alert status="error" mb="4">
              <AlertIcon />
              {errorMessage}
            </Alert>
          )}
          <Button
            colorScheme="green"
            onClick={handleCreateButtonClick}
            mb="4"
          >
            Create New Project
          </Button>
          <Box
            bg="white"
            boxShadow="md"
            rounded="lg"
            border="1px"
            borderColor="gray.200"
          >
            <Modal
              isOpen={isCreateModalOpen}
              onClose={() => setCreateModalOpen(false)}
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Create New Project</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <FormControl>
                    <FormLabel>Project Name</FormLabel>
                    <Input
                      placeholder="Enter project name"
                      value={newProjectTitle}
                      onChange={(e) => setNewProjectTitle(e.target.value)}
                    />
                  </FormControl>
                </ModalBody>

                <ModalFooter>
                  <Button
                    colorScheme="blue"
                    mr={3}
                    onClick={handleCreateNewProject}
                  >
                    Save
                  </Button>
                  <Button
                    variant="ghost"
                    onClick={() => setCreateModalOpen(false)}
                  >
                    Cancel
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>

            {projects.length > 0 ? (
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>Project Title</Th>
                    <Th>Project ID</Th>
                    <Th>Action</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {projects.map((project, index) => (
                    <Tr key={index}>
                      <Td>{project.title}</Td>
                      <Td>{project.projectId}</Td>
                      <Td>
                        <Button
                          colorScheme="teal"
                          onClick={() => {
                            setEditingProject({ ...project });
                            setEditModalOpen(true);
                          }}
                          mr="2"
                        >
                          <EditIcon />
                        </Button>
                        <Button
                          colorScheme="red"
                          onClick={() => openDeleteModal(project)}
                        >
                          <CloseIcon />
                        </Button>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            ) : (
              <p>No projects found</p>
            )}
          </Box>
        </Box>
      </VStack>

      <Modal isOpen={isEditModalOpen} onClose={() => setEditModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Project</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Project Name</FormLabel>
              <Input
                placeholder="Enter new project name"
                value={editingProject?.title}
                onChange={handleEditInputChange}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleEditProject}>
              Save
            </Button>
            <Button variant="ghost" onClick={() => setEditModalOpen(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Project</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure you want to delete this project?</ModalBody>
          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={confirmDelete}>
              Delete
            </Button>
            <Button variant="ghost" onClick={() => setDeleteModalOpen(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}

export default ManageProjects;
