import React from 'react';
import { Flex, Text, Button, Avatar, Stack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import '../styling/navbar.css';

const Navbar = ({ onLogout, userName, title, showDashboardButton }) => {
  const navigate = useNavigate(); // Hook for navigation

  const handleDashboardClick = () => {
    navigate('/dashboard'); // Function to navigate to dashboard
  };

  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      padding="1.5rem"
      bg="white"
      color="gray.600"
      borderBottomWidth="1px"
      borderBottomColor="gray.200"
    >
      {/* Hamburger Menu and Title */}
      <Flex align="center" mr={5}>
        <Text className="navbar-text" fontSize="xl" fontWeight="bold" display={{ base: 'block', md: 'block' }}>
          {title}
        </Text>
      </Flex>
      <Stack direction="row" spacing={4} align="center">
        {showDashboardButton === 1 && (
          <Button size="sm" onClick={handleDashboardClick}>
            Dashboard
          </Button>
        )}
        <Button size="sm" onClick={onLogout}>
          Logout
        </Button>
      </Stack>
    </Flex>
  );
};

export default Navbar;
