import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  VStack,
  Flex,
  Text,
  Input,
  useToast,
  IconButton,
  Heading,
} from "@chakra-ui/react";
import { CloseIcon, AddIcon } from "@chakra-ui/icons";
import apiServiceInstance from "../services/ApiService";
import { AuthContext } from "../services/AuthContext";

function CueCards({ projectId }) {
  const [cueCards, setCueCards] = useState([]);
  const { currentUser } = useContext(AuthContext);
  const toast = useToast();

  useEffect(() => {
    if (currentUser) {
      const fetchCueCards = async () => {
        try {
          const response = await apiServiceInstance.get(
            `cuecard?projectId=${projectId}`
          );
          setCueCards(response);
        } catch (error) {
          toast({
            title: "Error fetching cue cards",
            status: "error",
            duration: 2000,
          });
        }
      };
      fetchCueCards();
    }
  }, [currentUser]);

  const handleRemoveCueCard = async (_id) => {
    if (currentUser.email) {
      try {
        await apiServiceInstance.delete(`/cuecard/${_id}`);
        const updatedCueCards = cueCards.filter((c) => c._id !== _id);
        setCueCards(updatedCueCards);
        toast({ title: "Cue card removed", status: "success", duration: 2000 });
      } catch (error) {
        toast({
          title: "Error removing cue card",
          status: "error",
          duration: 2000,
        });
      }
    }
  };

  const handleAddCueCard = async (event) => {
    event.preventDefault();
    if (currentUser.email) {
      const newCueCardData = {
        question: event.target.question.value,
        answer: event.target.answer.value,
        projectId,
      };

      try {
        const response = await apiServiceInstance.post("/cuecard", newCueCardData);
        const newCueCard = response;
        setCueCards([...cueCards, newCueCard]);
        toast({ title: "Cue card added", status: "success", duration: 2000 });
        event.target.question.value = "";
        event.target.answer.value = "";
      } catch (error) {
        toast({
          title: "Error adding cue card",
          status: "error",
          duration: 2000,
        });
      }
    }
  };

  const flipCueCard = (index) => {
    const updatedCueCards = [...cueCards];
    updatedCueCards[index].flipped = !updatedCueCards[index].flipped;
    setCueCards(updatedCueCards);
  };

  const renderCueCards = () => (
    <Flex wrap="wrap" justifyContent="flex-start" gap="20px">
      {cueCards.map((cueCard, index) => (
        <Box
          key={`${cueCard._id}_${index}`}
          p="20px"
          bg="white"
          boxShadow="md"
          border="1px solid"
          borderColor="gray.300"
          borderRadius="lg"
          width="calc(33.333% - 20px)" // adjust width for 3 cards per row
          minHeight="160px" // adjust height for uniformity
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          onClick={() => flipCueCard(index)}
          style={{ cursor: "pointer" }}
        >
          <Heading size="md" mb="2">
            {cueCard.flipped ? cueCard.answer : cueCard.question}
          </Heading>
          {cueCard.flipped && (
            <IconButton
              icon={<CloseIcon />}
              size="sm"
              colorScheme="red"
              aria-label="Remove cue card"
              alignSelf="flex-end"
              mt="4"
              onClick={(e) => {
                handleRemoveCueCard(cueCard._id);
              }}
            />
          )}
        </Box>
      ))}
    </Flex>
  );

  return (
    <Box p="4">
      <form onSubmit={handleAddCueCard}>
        <VStack spacing={4} align="stretch">
          <Input name="question" placeholder="Question" required />
          <Input name="answer" placeholder="Answer" required />
          <IconButton
            icon={<AddIcon />}
            type="submit"
            colorScheme="green"
            aria-label="Add cue card"
          />
        </VStack>
      </form>
      <Box mt="5">
        {renderCueCards()}
      </Box>
    </Box>
  );
}

export default CueCards;
