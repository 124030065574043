import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  VStack,
  HStack,
  Text,
  Button,
  Input,
  useToast,
  IconButton,
  Heading,
} from "@chakra-ui/react";
import { CloseIcon, AddIcon } from "@chakra-ui/icons";
import apiServiceInstance from "../services/ApiService";
import { AuthContext } from "../services/AuthContext";

function TaskManager({ projectId }) {
  const [deadlines, setDeadlines] = useState([]);
  const { currentUser } = useContext(AuthContext); // Use the AuthContext to get the current user
  const toast = useToast();

  useEffect(() => {
    if (currentUser) {
      const fetchDeadlines = async () => {
        try {
          const response = await apiServiceInstance.get(
            `deadlines?projectId=${projectId}`
          );
          setDeadlines(response);
        } catch (error) {
          toast({
            title: "Error fetching deadlines",
            status: "error",
            duration: 2000,
          });
        }
      };
      fetchDeadlines();
    }
  }, [currentUser, projectId]);

  const formStyle = {
    display: "flex",
    gap: "10px",
    marginBottom: "20px",
  };

  const deadlineBoxStyle = {
    padding: "15px",
    margin: "10px 0",
    backgroundColor: "gray.200",
    borderRadius: "md",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };

  const addDeadlineStyle = {
    padding: "px",
    margin: "10px 0",
    backgroundColor: "green.200",
    borderRadius: "md",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };

  const formatDate = (dateString) => {
    const utcDate = new Date(dateString);
    const localDate = new Date(utcDate.toLocaleString('en-US', { timeZone: 'UTC' }));
    return localDate.toLocaleDateString(); 
  };
  
  const handleRemoveDeadline = async (deadlineId) => {
    if (currentUser.email) {
      try {
        await apiServiceInstance.delete(
          `/deadline/${deadlineId}/${currentUser.email}`
        );
        const updatedDeadlines = deadlines.filter(
          (d) => d.deadlineId !== deadlineId
        );
        setDeadlines(updatedDeadlines);
        toast({ title: "Deadline removed", status: "success", duration: 2000 });
      } catch (error) {
        toast({
          title: "Error removing deadline",
          status: "error",
          duration: 2000,
        });
      }
    }
  };

  const handleAddDeadline = async (event) => {
    event.preventDefault();
    if (currentUser.email) {
      const newDeadlineData = {
        title: event.target.title.value,
        date: event.target.date.value,
        projectId,
        userId: currentUser.email,
      };

      try {
        const response = await apiServiceInstance.post("/deadline", newDeadlineData); 
        const newDeadline = response;
        setDeadlines([...deadlines, newDeadline]);
        toast({ title: "Deadline added", status: "success", duration: 2000 });
        event.target.title.value = "";
        event.target.date.value = "";
      } catch (error) {
        toast({
          title: "Error adding deadline",
          status: "error",
          duration: 2000,
        });
      }
    }
  };

  const renderDeadlines = () =>
    deadlines.map((deadline) => (
      <Box key={deadline.deadlineId} sx={deadlineBoxStyle}>
        <VStack align="start">
          <Text fontSize="sm" fontWeight="bold">
            {deadline.title}
          </Text>
          <Text fontSize="xs" color="gray.600">
            Due Date: {formatDate(deadline.date)}
          </Text>
        </VStack>
        <IconButton
          icon={<CloseIcon />}
          size="sm"
          colorScheme="red"
          aria-label="Remove deadline"
          onClick={() => handleRemoveDeadline(deadline.deadlineId)}
        />
      </Box>
    ));
    return (
        <Box>
          <form onSubmit={handleAddDeadline} style={formStyle}>
            <Input name="title" placeholder="Title" required />
            <Input name="date" type="date" required />
            <IconButton
              icon={<AddIcon />} // Use AddIcon for a "+" icon
              type="submit"
              colorScheme="green"
              aria-label="Add deadline"
            />
          </form>
          {renderDeadlines()}
        </Box>
      );
    }
    
    export default TaskManager;