import React from 'react';
import { useAuth } from '../services/AuthContext';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td
} from '@chakra-ui/react';

function ProjectTable() {
  const { projects } = useAuth();

  return (
    <Box p="4">
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th fontSize="xs">Project Title</Th>
          </Tr>
        </Thead>
        <Tbody>
          {projects.map((project, index) => (
            <Tr fontSize="xs" key={index} align="center" justify="center">
            <Td fontSize="xs">{project.title}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
}

export default ProjectTable;
